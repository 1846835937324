$(document).ready(function() {
  // slick carousel
  $('.one-time').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

   $('.slider-for').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: false,
	  fade: true,
	  asNavFor: '.slider-nav'
	});
	$('.slider-nav').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  asNavFor: '.slider-for',
	  dots: false,
	  arrows: false,
	  centerMode: false,
	  focusOnSelect: true
	});
		
});

